import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";


const rootReducer = combineReducers({
    // public
    Layout,
   
});

export default rootReducer;